import { applyMiddleware, compose, createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import rootReducer from "./reducers"
import createSagaMiddleware from "redux-saga"
import { rootSaga } from "./sagas"
const persistConfig = {
  key: "root",
  storage,
  whiteList: ["Auth"],
  blacklist: ["Automation"],
}
export type AppState = ReturnType<typeof rootReducer>
const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
const w: any = window as any
const composeEnhancers = w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
function configureStore() {
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)))
  sagaMiddleware.run(rootSaga)
  return store
}
const store = configureStore()
const persistor = persistStore(store)
export { store, persistor }