import Parse from "parse"
import { produce } from "immer"
import * as types from "./actionTypes"
import { parseConfig } from "../../utils/ParseConfig"
import { LOGOUT } from "../Auth/actionTypes"

parseConfig()

const initialState = {
  AllReviews: [],
}

const Feedback = produce((state, action) => {
  switch (action.type) {
    case LOGOUT:
      state.AllReviews = []
      break
    case types.GET_FEEDBACK_LIST_SUCCESS:
      state.AllReviews = action.payload
      break

    default:
      break
  }
}, initialState)

export default Feedback