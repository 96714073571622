import { combineReducers } from "redux"
import AuthReducer from "./Auth/reducer"
import BookingReducer from "./Booking/reducer"
import AppointmentReducer from "./Appointment/reducer"
import VirtualReducer from "./Virtual/reducer"
import PeopleManagementReducer from "./PeopleManagement/reducer"
import ScheduleReducer from "./Schedule/reducer"
import PatientReducer from "./Patient/reducer"
import LayoutReducer from "./Layout/reducer"
import AutomationReducer from "./Automation/reducer"
import FeedbackReducer from "./Feedback/reducer"
import ReviewPlatformAddress from "./ReviewPlatformAddress/reducer"

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Booking: BookingReducer,
  Appointment: AppointmentReducer,
  Virtual: VirtualReducer,
  PeopleManagement: PeopleManagementReducer,
  Schedule: ScheduleReducer,
  Patient: PatientReducer,
  Layout: LayoutReducer,
  Automation: AutomationReducer,
  Feedback: FeedbackReducer,
  ReviewPlatformAddress: ReviewPlatformAddress,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer