import { produce } from "immer"
import { LOGOUT } from '../Auth/actionTypes';

const initialState = {
  phoneNo: null,
};

const Auth = produce((state, action) => {
  switch (action.type) {
    case LOGOUT:
      state.phoneNo = null
      break
    default:
      break;
  }
}, initialState);

export default Auth;
