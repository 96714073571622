import Parse from "parse"
import { produce } from "immer"
import * as types from "./actionTypes"
import { LOGOUT } from "../Auth/actionTypes"

const initialState = {
  patientTypes: [],
  user: Parse.User.current() ? Parse.User.current() : null,
  familyMember: null,
}

const Patient = produce((state, action) => {

  switch (action.type) {
    case LOGOUT: {
      state.patientTypes = []
      state.user = null
      state.familyMember = null
      break
    }
    default:
      break
    case types.UPDATE_PATIENT_INFO: {
      state.user = Parse.User.current()
      break
    }
    case types.UPLOAD_ATTACHMENT: {
      state.user = Parse.User.current()
      break
    }
    case types.GET_PATIENT_TYPES_SUCCESS:
      state.patientTypes = action.payload
      break

    case types.GET_FAMILY_MEMBER_SUCCESS:
      state.familyMember = action.payload
      break
  }
}, initialState)

export default Patient
