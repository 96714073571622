import Parse from "parse"
import { produce } from "immer"
import * as types from "./actionTypes"
import { parseConfig } from "../../utils/ParseConfig"
import { LOGOUT } from "../Auth/actionTypes"

parseConfig()

const initialState = {
  LocationReviewLinks: [],
}

const ReviewPlatformAddress = produce((state, action) => {
  switch (action.type) {
    case LOGOUT:
      state.LocationReviewLinks = []
      break
    case types.GET_REVIEW_PLATFORM_ADDRESSES_SUCCESS:
      state.LocationReviewLinks = action.payload
      break

    default:
      break
  }
}, initialState)

export default ReviewPlatformAddress
