import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { enUS } from "date-fns/locale"
import { TextField } from "@mui/material"

export const DateRangePickerComp = ({ startRangeDate, endRangeDate, setStartRangeDate, setEndRangeDate }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <div className="date-range flex justify-between text-[14px]">
        <DatePicker 
          value={startRangeDate}
          onChange={(date) => setStartRangeDate(date)}
          maxDate={endRangeDate || new Date()}
          renderInput={(props) => (
            <TextField 
              {...props} 
              size="small" 
              placeholder="Start date"
              className="w-28 mr-1"
              InputProps={{ 
                className: "input-focused bg-white border border-1 border-gray-200 rounded"
              }}
            />
          )}
        />
        <DatePicker 
          value={endRangeDate}
          onChange={(date) => setEndRangeDate(date)}
          minDate={startRangeDate}
          maxDate={new Date()}
          renderInput={(props) => (
            <TextField 
              {...props} 
              size="small" 
              placeholder="End date"
              className="w-28"
              InputProps={{ 
                className: "input-focused bg-white border border-1 border-gray-200 rounded"
              }}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  )
}