import { useState, useEffect, useRef } from "react"
import { MessageWrapper } from "../../components/DataDisplay/Table/Table.utils"
import Error from "../../components/DataDisplay/Error"
import * as Parse from "parse"
import moment from "moment-timezone"
import Button from "../../components/Inputs/Button"
import BillingConfirmationPopup from "./billingConfirmationPopup"
import { toast } from "react-toastify"
import { getLocationName } from "../../utils/helpers"
import Loader from "../../components/Loader"
import { Tab } from "@headlessui/react"
import classNames from "classnames"

const BillingList = (): JSX.Element => {
    const [appointments, setAppointments] = useState<any>([])
    const [confirmationPopup, setConfirmationPopup] = useState<any>(false)
    const [billingAppointment, setBillingAppointment] = useState<any>()
    const [confirmationPopupTitle, setConfirmationPopupTitle] = useState<any>()
    const [confirmationPopupBody, setConfirmationPopupBody] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [firstLoad, setFirstLoad] = useState<any>(false)
    const [chargeCount, setChargeCount] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0)

    const getNoShowAppointments: any = async () => {
        const response = await Parse.Cloud.run("getNoShowPatientAppointments").catch((error) => {
            toast.error(JSON.stringify(error))
        });
        setAppointments(response.appointments);
        setChargeCount(response.chargeCount);
    }

    const firstCall = async () => {
        setFirstLoad(true)
        await getNoShowAppointments();
        setFirstLoad(false)
    }

    useEffect(() => {
        firstCall()
    }, [])

    const handleCharge = async (appointmentId: any) => {
        try {
            console.log(appointmentId);
            setLoading(true)
            setChargeCount(0)
            const appointment = await Parse.Cloud.run("chargeAppointment", { appointmentId: appointmentId }).catch((error) => {
                setConfirmationPopup(false)
                setLoading(false)
                toast.error(error.message)
            })
            if (appointment) {
                if (appointment.get('squarePayment')) {
                    toast.success("Successfully Completed Payment")
                } else if (appointment.get('legderProcedureAdded')) {
                    toast.error(`Successfully Posted Legder Entry`)
                }
                await getNoShowAppointments()
                setConfirmationPopup(false)
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(JSON.stringify(error.message))
        }
    }

    return (
        <>
            <div className="flex flex-1 flex-col h-full dark:text-white col-span-6">
                <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                    <Tab.List className="flex justify-between items-center mb-5">
                        <div className="flex justify-center items-center relative">
                            <TabItem>Broken / No-Show Fees</TabItem>
                        </div>
                    </Tab.List>

                    {firstLoad ? <Loader /> :
                        <div className="flex flex-1 flex-col font-medium relative">
                            {appointments.length > 0 ? (
                                <>
                                    <div className="grid-rows-none p-1.5">
                                        <span className="float-left">
                                            Total amount: ${Number(((appointments.length * 50)).toFixed(1)).toLocaleString()}
                                        </span>
                                        <span className="float-right">
                                            Amount charged: ${chargeCount}
                                        </span>
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="overflow-x-auto">
                                            <div className="w-full inline-block align-middle">
                                                <div className="border rounded-lg overflow-y-auto h-full max-h-[calc(100vh-180px)]">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Patient Name
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Appointment Start Date
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Appointment Location
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Days Ago
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Appointment Status
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Card On File
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Charge status
                                                                </th>
                                                                <th scope="col" className="px-4 py-2 text-xs font-bold text-left text-gray-500 uppercase ">
                                                                    Charge ($50)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="divide-y divide-gray-200">
                                                            {appointments.map((appointment: any, i: any) => {
                                                                const start = moment(appointment?.get("start")).format("dddd, MMM D, YYYY")
                                                                const daysAgo = moment.utc().diff(moment(appointment?.get("start")).utc(), "days")
                                                                const appointmentLocation = getLocationName(appointment?.get("location").get("name"))
                                                                const dob = moment(appointment?.get("patient")?.get("dateOfBirth")).format("MMM-DD-YYYY")
                                                                const paymentStatus = appointment.get("squarePayment") ? "CHARGED" : appointment.get("legderProcedureAdded") ? "POSTED" : "NOT CHARGED";
                                                                let cardOnFile;
                                                                let cardOnFileCss;
                                                                const hasCard = (appointment?.get("patient")?.get("squareCustomer") || appointment?.get("patient")?.get("squareCard"));
                                                                cardOnFile = hasCard ? 'Card on file' : 'Card not on file';
                                                                cardOnFileCss = hasCard ? 'text-lime-700' : 'text-red-600';
                                                                return (
                                                                    <tr key={i} className="hover:bg-gray-100 dark:hover:bg-black-800 transition duration-200 cursor-pointer">
                                                                        <td className="px-4 py-2 text-xs text-sm">
                                                                            <div>
                                                                                <a className="text-lime-700 hover:text-green-900" href={`https://live4.dentrixascend.com/pm#/patient/overview/${appointment?.get("patient")?.id}`} target="_blank">
                                                                                    <b>{appointment?.get("patient")?.get("firstName")} {appointment?.get("patient")?.get("lastName")}</b>
                                                                                </a>
                                                                                <span>&nbsp;{`(${dob})`}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-4 py-2 text-xs text-sm">{start}</td>
                                                                        <td className="px-4 py-2 text-xs text-sm">{appointmentLocation}</td>
                                                                        <td className="px-4 py-2 text-xs text-sm">{daysAgo}</td>
                                                                        <td className="px-4 py-2 text-xs text-sm">{appointment?.get("status")}</td>
                                                                        <td className={`px-4 py-2 text-xs text-sm ${cardOnFileCss}`}>{cardOnFile}</td>
                                                                        <td className="px-4 py-2 text-xs text-sm">{paymentStatus}</td>
                                                                        <td className="px-4 py-2 text-xs text-sm">
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setConfirmationPopupTitle("Charge no show fee!")
                                                                                    setConfirmationPopupBody("Are you sure you want to continue changing to patient:")
                                                                                    setConfirmationPopup(true)
                                                                                    setBillingAppointment(appointment)
                                                                                }}
                                                                                variant="contained"
                                                                                disabled={paymentStatus !== "NOT CHARGED"}
                                                                                color={paymentStatus !== "NOT CHARGED" ? "green-light" : "red"}
                                                                            >
                                                                                {paymentStatus === "NOT CHARGED" ? "CHARGE" : paymentStatus}
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <MessageWrapper>
                                    <Error type="no-data" title="" message={"No Data in Billing Section"} styles={{ position: "relative", top: "0", right: "0", left: "0", bottom: "0" }} />
                                </MessageWrapper>
                            )}
                            <BillingConfirmationPopup handleCharge={handleCharge} confirmationPopup={confirmationPopup} appointment={billingAppointment} setConfirmationPopup={setConfirmationPopup} title={confirmationPopupTitle} bodyText={confirmationPopupBody} loading={loading} />
                        </div>}
                </Tab.Group>
            </div>
        </>
    )
}
export default BillingList


const TabItem = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return <Tab className={({ selected }): string => classNames(selected ? "border-green-600 text-green-600" : "text-gray-500 hover:text-gray-700 hover:border-gray-400", "whitespace-nowrap py-4 px-5 border-b-2 font-medium text-sm outline-none")}>{children}</Tab>
}